import React, { Dispatch, FC, SetStateAction, useContext } from "react";
import "./elections-module-result.scss";
import PrimaryButton from "../../../primary-button";
import { ElectionEventRegionColors, ElectionEventRegionData, ElectionEventRegionsData } from "../../../../types/types";
import mapboxgl from 'mapbox-gl';
import { toggleBarsLayersIds } from "../../../../models/utils";
import { MapContext } from "../../../../helpers/context-provider/context";

interface ElectionsModuleResultProps {
  mapRef: any;
  selectedRegion: string;
  popup: any;
  legend: ElectionEventRegionData[];
  setLegend: Dispatch<SetStateAction<ElectionEventRegionData[]>>;
}

const getNormalizeName = (value: string) => {
  if (
    value.includes("Санкт-Петербург") ||
    value.includes("Севастополь") ||
    value.includes("Москва")
  ) {
    return `г. ${value}`;
  }
  if (value.toLowerCase() === "чувашия") {
    return "Чувашская Республика";
  }
  if (value.toLowerCase() === "чувашская республика") {
    return "Чувашская Республика";
  }
  if (value.toLowerCase() === "удмуртская республика") {
    return "Удмуртская Республика"
  }
  if (value.toLowerCase() === "удмуртия") {
    return "Удмуртская Республика"
  }
  if (value.toLowerCase().includes('югра')) {
    return "Ханты-Мансийский автономный округ — Югра";
  }
  if (value.toLowerCase().includes("кемеровская область - кузбасс")) {
    return "Кемеровская область";
  }
  if (value.toLowerCase().includes("северная осетия")) {
    return "Республика Северная Осетия-Алания";
  }
  if (value.toLowerCase().includes("республика северная осетия - алания")) {
    return "Республика Северная Осетия-Алания";
  }
  if (value.toLowerCase().includes("республика саха")) {
    return "Республика Саха (Якутия)";
  }
  if (value.toLowerCase().includes("якутия")) {
    return "Республика Саха (Якутия)";
  }
  if (value.toLowerCase().includes("кабардино-балкария")) {
    return "Кабардино-Балкарская Республика";
  }
  if (value.toLowerCase().includes("кабардино-балкарская республика")) {
    return "Кабардино-Балкарская Республика";
  }
  if (value.toLowerCase().includes("карачаево-черкесия")) {
    return "Карачаево-Черкесская Республика";
  }
  if (value.toLowerCase().includes("карачаево-черкесская республика")) {
    return "Карачаево-Черкесская Республика";
  }
  if (value.toLowerCase().includes("чечня")) {
    return "Чеченская Республика";
  }
  if (
    value === "Адыгея" ||
    value === "Башкортостан" ||
    value === "Бурятия" ||
    value === "Дагестан" ||
    value === "Ингушетия" ||
    value === "Калмыкия" ||
    value === "Марий Эл" ||
    value === "Мордовия" ||
    value === "Саха (Якутия)" ||
    value === "Северная Осетия-Алания" ||
    value === "Татарстан" ||
    value === "Тыва" ||
    value === "Хакасия"
  ) {
    return `Республика ${value}`;
  }
  return value;
};

const colors = [
  "#4169E1",
  "#E30B5D",
  "#FF00FF",
  "#228B22",
  "#F7E7CE",
  "#FFFDD0",
  "#DCAE96",
  "#E6E6FA",
  "#B8B8AA",
  "#E2725B",
  "#FFDB58",
  "#FFDAB9",
  "#FF7F50",
  "#C68E17",
  "#93E9BE",
  "#7FFFD4",
  "#AFCBFF",
  "#98FF98",
  "#9B111E",
  "#FFF44F",
];

const prepareTempData = (
  data: ElectionEventRegionsData[],
  newData: ElectionEventRegionColors[],
) => {
  
  const result = data.map((item) => {
    const winnerCandidate = item.results[0];
    const color = newData.find((item) => getNormalizeName(item.name) === getNormalizeName(winnerCandidate.name))
    return [getNormalizeName(item.region), color.color];
  });
  return result;
};

export const ElectionsModuleResult: FC<ElectionsModuleResultProps> = (props) => {
  const {
    selectedElectionEventData,
    setIsShowElectionsResultOnMap,
    setTemperature,
    setTemperatureParam,
    selectedElectionEvent
  } = useContext(MapContext);

  const preparedRegions = selectedElectionEventData.regions.map((item) => {
    const newData = item.results
      .filter((innerItem) => innerItem.name !== "Явка" && innerItem.name !== "Недействительные бюллетени" && innerItem.name !== "Испорченные бюллетени" && !innerItem.name.startsWith('Число'))
      .sort((itemFirst, itemSecond) => itemSecond.percentage - itemFirst.percentage);
    return { region: item.region, results: newData};
  });

  const handleShowResult = () => {
    setIsShowElectionsResultOnMap(true);
    const listOfParties = preparedRegions[0].results;
    const listOfPartiesColors = listOfParties.map((item, index) => ({ ...item, color: colors[index] }));

    props.setLegend(listOfPartiesColors);

    const temp_data = prepareTempData(preparedRegions, listOfPartiesColors);

    // hiding previous layers
    if (props.mapRef.current.getLayer("temperature")) {
      props.mapRef.current.removeLayer("temperature");
      // props.mapRef.current.removeSource("temperature");
    }
    if (props.mapRef.current.getLayer('places-tik')) {
      props.mapRef.current.removeLayer('places-tik');
      props.mapRef.current.removeSource('places-tik');
    }
    if (props.mapRef.current.getLayer('places-uik')) {
      props.mapRef.current.removeLayer('places-uik');
      props.mapRef.current.removeSource('places-uik');
    }
    if (props?.mapRef?.current?.getLayer("places")) {
      props.mapRef.current.removeLayer("places");
      props.mapRef.current.removeSource("places");
    }
    if (props?.mapRef?.current?.getLayer("elections-results")) {
      props.mapRef.current.removeLayer("elections-results");
      // props.mapRef.current.removeSource("elections-results");
    }
    toggleBarsLayersIds.forEach((layerId) => {
      props.mapRef.current.setLayoutProperty(layerId, "visibility", "none");
      props.mapRef.current.setLayoutProperty(
        layerId + "-outline",
        "visibility",
        "none",
      );
    });
    props.mapRef.current.setLayoutProperty(
      "regions-outline",
      "visibility",
      "none",
    );

    const matchArray = temp_data.reduce((acc, [state, color]) => {
      acc.push(state, color);
      return acc;
    }, []);

    props.mapRef.current.addLayer({
      id: 'elections-results',
      type: 'fill',
      source: 'regions',
      paint: {
        'fill-color': [
          'match',
          ['get', 'name'],
          ...matchArray,
          '#333333'
        ],
        'fill-opacity': 0.5
      }
    });

    //adding temperature layer
    // props.mapRef.current.addSource("elections-results", {
    //   type: "geojson",
    //   data: geojsonDataTemp,
    // });
    // props.mapRef.current.addLayer({
    //   id: "elections-results",
    //   type: "fill",
    //   source: "elections-results",
    //   layout: {},
    //   paint: {
    //     "fill-color": ["get", "color"],
    //     "fill-opacity": 0.5,
    //   },
    // });

    props.mapRef.current.on('mousemove', 'elections-results', (e) => {
      const findRegion = preparedRegions.find((item) => getNormalizeName(item.region) === e.features[0].properties.name);
      const listP = findRegion?.results?.map((item) => (`<p>${item.name}: <span>${item.percentage}%</span></p>`))
      if (props.popup.current != null) {
        props.popup.current.setLngLat(e.lngLat);
        props.popup.current.setHTML(`<h3>${e.features[0].properties.name}</h3>${findRegion ? `<h4>результаты:</h4> ${listP.join('')}` : ''}`);
      }
    })

    props.mapRef.current.on('mouseenter', 'elections-results', (e) => {
      props.mapRef.current.getCanvas().style.cursor = 'pointer';
      if (props.popup.current === null) {
          props.popup.current = new mapboxgl.Popup({
              closeOnClick: false,
              closeButton: false,
          })
              .setLngLat(e.lngLat)
              .setHTML(e.features[0].properties.name)
              .addTo(props.mapRef.current);
        }
        props.popup.current
          .setLngLat(e.lngLat)
          .setHTML(`
            <h3>${e.features[0].properties.name}</h3>
          `)
          .addTo(props.mapRef.current);
    });

    // props.mapRef.current.on('mouseleave', 'elections-results', () => {
    //   props.mapRef.current.getCanvas().style.cursor = '';
    //   if (popup.current) {
    //     popup.current.remove();
    //   }
    //   popup.current = null;
    // });
    const prepareTempDate = temp_data.map(([region, color]) => ({ [region]: color }));
    // setting temperature name
    setTemperature(prepareTempDate);
    // props.change_temperature_param(props.selectedElectionEvent);
    setTemperatureParam(selectedElectionEvent);
  };

  const findSelectedRegion = selectedElectionEventData?.regions?.find((item) => getNormalizeName(item.region) === getNormalizeName(props.selectedRegion));
  const findSelectedFromPrepared = preparedRegions?.find((item) => getNormalizeName(item.region) === getNormalizeName(props.selectedRegion));
  const turnout = findSelectedRegion?.results?.find((item) => item.name === "Явка");
  const winner = preparedRegions?.find((item) => getNormalizeName(item.region) === getNormalizeName(props.selectedRegion))?.results?.[0];
  const countOfVoters = findSelectedRegion?.results?.find((item) => item.name === "Число избирателей, включенных в список избирателей");

  return (
    <>
        <div className="elections-module-container">
          <p className="elections-module-text">Число избирательных округов: <span className="elections-module-text-color">2</span></p>
          <p className="elections-module-text">Численность избирателей: <span className="elections-module-text-color">{countOfVoters?.value ? `${countOfVoters?.value} человек` : '1231442 человек'}</span></p>
          <p className="elections-module-text">Явка: <span className="elections-module-text-color">{turnout?.percentage === null ? turnout?.value : turnout?.percentage }%</span></p>
          <p className="elections-module-text mb-37">Победившая партия и кандидат от партии: <span className="elections-module-text-color">{winner?.name}</span></p>
          <div className="mb-37">
            <PrimaryButton onClick={handleShowResult}>Посмотреть на карте</PrimaryButton>
          </div>
          <table className="table">
            <thead className="thead">
              <tr className="tr-head">
                <th className="th"><span>Партия</span></th>
                <th className="th"><span>Кандидат</span></th>
                <th className="th"><span>Результат</span></th>
              </tr>
            </thead>
            <tbody className="tbody"> 
              {findSelectedFromPrepared?.results?.map((item) => (
                <tr key={item.name} className="tr-body">
                  <td className="td"><span>{item?.name}</span></td>
                  <td className="td"><span>{item?.name}</span></td>
                  <td className="td"><span>{item?.percentage === null ? item?.value : item?.percentage}</span></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    </>
  );
};
