import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import './style.scss';
import ContextProvider from './helpers/context-provider';


const root = ReactDOM.createRoot(document.getElementById('root'));

const RenderApp = React.memo(() => {
    return (
        <ContextProvider>
            <App />
            <section id="document-modal" />
        </ContextProvider>
    )
});

root.render(
    // <React.StrictMode>
    <RenderApp/>
    // </React.StrictMode>
);

export { default as App } from './app'
