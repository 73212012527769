import React from "react";
import { LittleOpenMenu } from "../";
import './extra-data-menu.scss'

interface ExtraDataProp {
    mapRef: any;
    popup: any;
}

const ExtraDataMenu = (props: ExtraDataProp) => {
    return (
        <section style={ { display: 'flex', flexDirection: 'column', gap: '10px' } }>
            <p>Выберите показатель, чтобы просмотреть тепловую карту</p>
            <LittleOpenMenu
                mapRef={props.mapRef}
                popup={props.popup}
            />
        </section>
    )
}

export default ExtraDataMenu;
