import React, { Dispatch, FC, SetStateAction, useCallback, useRef } from "react";
import { CommissionMemberData, Document, Election, ElectionEventData } from "../../../../types/types";
import mapboxgl from "mapbox-gl";
import './modal.scss';
import Api from "../../../../api/api";
import { addingLayerName, toggleBarsLayersIds } from "../../../../models/utils";

interface ModalProps {
  handleClickToggleModal: () => void;
  setIsLoadingElectionPoints: Dispatch<SetStateAction<boolean>>;
  mapRef: any;
  popup: any;
  // commissionMemberData: CommissionMemberData;
  setIsShowElectionPoints: Dispatch<SetStateAction<boolean>>;
  selectedDocument: Document;
  searchValue?: string;
  isOpenModal: boolean;
};

const escapeRegExp = (value: string): string =>
  value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const highlightText = (sourceText: string, searchValue: string): string => {
  if (!searchValue) return sourceText;
  // const [firstName] = searchValue.split(' ');

  const regex = new RegExp(`(${escapeRegExp(searchValue)})`, 'gi');
  return sourceText.replace(regex, (match) => `<span class="yellow">${match}</span>`);
};

const prepareText = (text: string, searchValue: string) => {
  return (
    <>
      {text.split('\n').map((line, index) => (
        <p
          key={index}
          className="document-text-modal"
          dangerouslySetInnerHTML={{ __html: highlightText(line, searchValue) }}
        />
      ))}
    </>
  );
};
 
export const Modal: FC<ModalProps> = (props) => {
  const handleClick = () => {
    props.handleClickToggleModal();
  };

  const api = new Api();

  const popup = useRef(null);

  const handleShowResult = useCallback(async () => {
    props.setIsLoadingElectionPoints(true);

    if (props?.mapRef?.current?.getLayer("temperature")) {
      props.mapRef.current.removeLayer("temperature");
      // props.mapRef.current.removeSource("temperature");
    }
    if (props.mapRef.current.getLayer('places')) {
      props.mapRef.current.removeLayer('places');
      props.mapRef.current.removeSource('places');
    }
    if (props.mapRef.current.getLayer('places-tik')) {
      props.mapRef.current.removeLayer('places-tik');
      props.mapRef.current.removeSource('places-tik');
    }
    if (props.mapRef.current.getLayer('places-uik')) {
      props.mapRef.current.removeLayer('places-uik');
      props.mapRef.current.removeSource('places-uik');
    }
    if (props?.mapRef?.current?.getLayer("elections-results")) {
      props.mapRef.current.removeLayer("elections-results");
      // props.mapRef.current.removeSource("elections-results");
    }
    for (const layers_group of addingLayerName)
      for (const key in layers_group)
          if (props.mapRef.current.getLayer(layers_group[key]))
              props.mapRef.current.removeLayer(layers_group[key]);

    toggleBarsLayersIds.forEach((layerId) => {
      props.mapRef.current.setLayoutProperty(layerId, "visibility", "none");
      props.mapRef.current.setLayoutProperty(
        layerId + "-outline",
        "visibility",
        "none",
      );
    });
    props.mapRef.current.setLayoutProperty(
      "regions-outline",
      "visibility",
      "none",
    );
    const res = await api.getElectionCommissions();

    const features = res.map((item) => {
      return {
        type: "Feature",
        properties: {
          name: `${item.name}`,
        },
        geometry: {
          type: "Point",
          coordinates: item.coords.split(", ").reverse(),
        },
      };
    });

    props.mapRef.current.addSource("places", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: features,
      },
    });

    props.mapRef.current.addLayer({
      id: "places",
      type: "circle",
      source: "places",
      paint: {
        "circle-color": "#4264fb",
        "circle-radius": 6,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#ffffff",
      },
    });

    props.mapRef.current.on("click", "places", async (e) => {
      e.preventDefault();
      const res = await api.getElectionCommission(
        e.features[0].properties.name,
      );
      if (popup.current) {
        props.mapRef.current.getCanvas().style.cursor = "";
        popup.current.remove();
        popup.current = null;
      }
      popup.current = new mapboxgl.Popup({
        closeOnClick: true,
        closeButton: true,
        closeOnMove: false,
      })
        .setLngLat(e.lngLat)
        .addTo(props.mapRef.current);
      popup.current.setHTML(`
            <div class="popup-list-container container">
              <p><span class="strong">Имя:</span> ${res.name}</p>
              <p><span class="strong">Адрес:</span> ${res.address}</p>
              <p><span class="strong">Телефонный номер:</span> ${res.phone_number}</p>
              <p><span class="strong">Срок окончания полномочий:</span> ${res.too}</p>
              ${res?.members && res?.members?.length > 0 ? (`
                  <p><span class="strong">Состав комиссии:</span></p>
                  <ul>
                    ${res?.members?.map((item) => `<li>${item.name}</li>`).join(" ")}
                  </ul>
                `
              ) : (
                ``
              )}
            </div>
            `);
    });

    props.mapRef.current.on('mousemove', 'places', (e) => {
      if (props.popup.current !== null) {
        props.popup.current.setLngLat(e.lngLat);
        props.popup.current.setHTML(`<h3>${e.features[0].properties.name}</h3>`);
      }
    })

    props.mapRef.current.on('mouseenter', 'places', (e) => {
      props.mapRef.current.getCanvas().style.cursor = 'pointer';
      if (props.popup.current === null) {
          props.popup.current = new mapboxgl.Popup({
              closeOnClick: false,
              closeButton: false,
          })
              .setLngLat(e.lngLat)
              .setHTML(e.features[0].properties.name)
              .addTo(props.mapRef.current);
        }
    });
    props.setIsLoadingElectionPoints(false);
  }, [api, props.mapRef]);

  const handleChange = () => {
    props.handleClickToggleModal();
    props.mapRef.current.flyTo({

      // center: props.commissionMemberData.election_commission.coords.split(', ').reverse(),
      zoom: 13.5,
      essential: true,
    });

    props.setIsShowElectionPoints(() => {
      handleShowResult();
      return true;
    });
  };

  return (
    <div className="section">
      <div className="backing" onClick={props.handleClickToggleModal} />
        <div className="inner-modal">
          <div className="document-header-modal">
            <p className="document-title-modal">{props.selectedDocument.title}</p>
            <div className="close-btn-container">
              <div onClick={handleClick} className="SvgCrossContainer">
                <span className="SvgCrossLine"></span>
                <span className="SvgCrossLine"></span>
              </div>
            </div>
          </div>
          <p className="document-number-modal">Номер документа: {props.selectedDocument.document_number}</p>
          {/* <p onClick={handleChange} className="document-address-modal">{props.commissionMemberData.election_commission.address}</p> */}
          {prepareText(props.selectedDocument.news_text, props.searchValue)}
        </div>
    </div>
  );
}
