import "./election-commission-controllers.scss";

import mapboxgl from "mapbox-gl";
import React, {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import Api from "../../api/api";
import { addingLayerName, toggleBarsLayersIds } from "../../models/utils";
import { ElectionModuleMode } from "../../types/types";
import BackButton from "../back-button";

interface ElectionCommissionControllersProps {
  setElectionModuleMode: Dispatch<SetStateAction<ElectionModuleMode>>;
  mapRef: any;
  popup: any;
  electionPointPopup: any;
  activeMapLayerId: MutableRefObject<any>;
  hideElectionsPoints: () => void;
}

export const ElectionCommissionControllers: FC<
  ElectionCommissionControllersProps
> = (props) => {
  const [isShowTik, setIsShowTik] = useState(true);
  const [isShowUik, setIsShowUik] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const api = new Api();

  const handleClickBack = () => {
    props.hideElectionsPoints();
    props.setElectionModuleMode("main");
  };

  const handleClickShowTik = () => {
    if (!isShowUik) return;
    setIsShowTik((prevValue) => !prevValue);
  };

  const handleClickShowUik = () => {
    if (!isShowTik) return;
    setIsShowUik((prevValue) => !prevValue);
  };

  const showElectionsCommissions = async () => {
    setIsLoading(true);
    if (props?.mapRef?.current?.getLayer("temperature")) {
      props.mapRef.current.removeLayer("temperature");
      // props.mapRef.current.removeSource("temperature");
    }
    if (props?.mapRef?.current?.getLayer("places")) {
      props.mapRef.current.removeLayer("places");
      props.mapRef.current.removeSource("places");
    }
    if (props?.mapRef?.current?.getLayer("elections-results")) {
      props.mapRef.current.removeLayer("elections-results");
      // props.mapRef.current.removeSource("elections-results");
    }
    if (props?.mapRef?.current?.getLayer("places-tik")) {
      props.mapRef.current.removeLayer("places-tik");
      props.mapRef.current.removeSource("places-tik");
    }
    if (props?.mapRef?.current?.getLayer("places-uik")) {
      props.mapRef.current.removeLayer("places-uik");
      props.mapRef.current.removeSource("places-uik");
    }

    for (const layers_group of addingLayerName)
      for (const key in layers_group)
        if (props.mapRef.current.getLayer(layers_group[key]))
          props.mapRef.current.removeLayer(layers_group[key]);

    toggleBarsLayersIds.forEach((layerId) => {
      props.mapRef.current.setLayoutProperty(layerId, "visibility", "none");
      props.mapRef.current.setLayoutProperty(
        layerId + "-outline",
        "visibility",
        "none",
      );
    });
    props.mapRef.current.setLayoutProperty(
      "regions-outline",
      "visibility",
      "none",
    );
    const res = await api.getElectionCommissions();

    const tikFeatures = res
      .filter((item) => isShowTik && item.name.includes("ТИК"))
      .map((item) => {
        const [latitude, longitude] = item.coords.split(", ");
        return {
          type: "Feature",
          properties: {
            name: `${item.name}`,
          },
          geometry: {
            type: "Point",
            coordinates: [longitude, latitude],
          },
        };
      });

    const uikFeatures = res
      .filter((item) => isShowUik && item.name.includes("Участковая"))
      .map((item) => {
        const [latitude, longitude] = item.coords.split(", ");
        return {
          type: "Feature",
          properties: {
            name: `${item.name}`,
          },
          geometry: {
            type: "Point",
            coordinates: [longitude, latitude],
          },
        };
      });

    props.mapRef.current.addSource("places-tik", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: tikFeatures,
      },
    });

    props.mapRef.current.addLayer({
      id: "places-tik",
      type: "circle",
      source: "places-tik",
      paint: {
        "circle-color": "#23BC0F",
        "circle-radius": 6,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#ffffff",
      },
    });

    props.mapRef.current.addSource("places-uik", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: uikFeatures,
      },
    });

    props.mapRef.current.addLayer({
      id: "places-uik",
      type: "circle",
      source: "places-uik",
      paint: {
        "circle-color": "#4264fb",
        "circle-radius": 6,
        "circle-stroke-width": 2,
        "circle-stroke-color": "#ffffff",
      },
    });

    props.mapRef.current.on("click", ["places-tik", "places-uik"], async (e) => {
      e.preventDefault();
      const res = await api.getElectionCommission(
        e.features[0].properties.name,
      );
      if (props.electionPointPopup.current) {
        props.mapRef.current.getCanvas().style.cursor = "";
        props.electionPointPopup.current.remove();
        props.electionPointPopup.current = null;
      }
      props.electionPointPopup.current = new mapboxgl.Popup({
        closeOnClick: true,
        closeButton: true,
        closeOnMove: false,
      })
        .setLngLat(e.lngLat)
        .addTo(props.mapRef.current);
        props.electionPointPopup.current.setHTML(`
            <div class="popup-list-container container">
              <p><span class="strong">Имя:</span> ${res.name}</p>
              <p><span class="strong">Адрес:</span> ${res.address}</p>
              <p><span class="strong">Телефонный номер:</span> ${res.phone_number}</p>
              <p><span class="strong">Срок окончания полномочий:</span> ${res.too}</p>
              ${
                res?.members && res?.members?.length > 0
                  ? `
                  <p><span class="strong">Состав комиссии:</span></p>
                  <ul class="popup-member-list">
                    ${res?.members?.map((item) => `<li>${item.name}</li>`).join(" ")}
                  </ul>
                `
                  : ``
              }
            </div>
          `);
    });

    props.mapRef.current.on("mousemove", ["places-tik", "places-uik"], (e) => {
      if (props.popup.current !== null) {
        props.popup.current.setLngLat(e.lngLat);
        props.popup.current.setHTML(
          `<h3>${e.features[0].properties.name}</h3>`,
        );
      }
    });

    props.mapRef.current.on("mouseenter", ["places-tik", "places-uik"], (e) => {
      props.mapRef.current.getCanvas().style.cursor = "pointer";
      if (props.popup.current === null) {
        props.popup.current = new mapboxgl.Popup({
          closeOnClick: false,
          closeButton: false,
        })
          .setLngLat(e.lngLat)
          .setHTML(e.features[0].properties.name)
          .addTo(props.mapRef.current);
      }
    });
    setIsLoading(false);
  };

  useEffect(() => {
    showElectionsCommissions();
  }, [isShowTik, isShowUik]);

  return (
    <>
      <BackButton
        className="election-commission-back-btn"
        onClick={handleClickBack}
      />
      <div className="election-commission-controllers-container">
        <div className="election-commission-inner-container">
          <p className="election-commission-inner-text text-center">
            Карта избирательных комиссий
          </p>
        </div>
        <div className="election-commission-inner-container">
          <div className="input-inner-container">
            <p className="election-commission-inner-text">Показать ТИК</p>
            <input
              onChange={handleClickShowTik}
              checked={isShowTik}
              type="checkbox"
              disabled={isLoading}
            />
          </div>
          <div className="input-inner-container">
            <p className="election-commission-inner-text">Показать УИК</p>
            <input
              onChange={handleClickShowUik}
              checked={isShowUik}
              type="checkbox"
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};
