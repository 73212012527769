import React, { ChangeEvent, Dispatch, FC, SetStateAction, useMemo, useRef, useState } from 'react';
import Api from '../../../api/api';
import { CommissionMemberData, Document, Election, ElectionEventData, Member } from '../../../types/types';
import { DocumentModal } from '../../document-modal/document-modal';
import Loader from '../../loader';
import SVGIcon from '../../svg-icon';
import '../document-bank-grid/document-bank-grid.scss';
import './document-bank-grid.scss';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const escapeRegExp = (value: string): string => value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const highlightText = (sourceText: string, searchValue: string): string => {
  if (!searchValue) {
    return sourceText;
  }

  const regex = new RegExp(`(${escapeRegExp(searchValue)})`, 'gi');
  return sourceText.replace(regex, (match) => `<span class="yellow">${match}</span>`);
};

const prepareText = (text: string, searchValue: string, className: string) => {
  return (
    <p
      className={className}
      dangerouslySetInnerHTML={{ __html: highlightText(text, searchValue) }}
    />
  );
};
interface DocumentBankGridProps {
  mapRef: any;
  // regionsData: any;
  popup: any;
}

const DocumentBankGrid: FC<DocumentBankGridProps> = (props) => {
  const api = new Api();
  const [value, setValue] = useState('');
  const [isLoadingMembers, setIsLoadingMembers] = useState(false);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectDocument, setSelectDocument] = useState<Document | null>(null);
  const [members, setMembers] = useState<Member[] | undefined>();
  const [commissionMemberData, setCommissionMemberData] = useState<CommissionMemberData | null>(null);
  const [pageMembers, setPageMembers] = React.useState(1);
  const [pageDocuments, setPageDocuments] = React.useState(1);

  const onChange = (inputValue: ChangeEvent<HTMLInputElement>) => {
    setValue(inputValue.target.value.trim());
  };

  const handleCLickSearch = async () => {
    setIsLoadingMembers(true);
    setPageMembers(1);
    setPageDocuments(1);
    setCommissionMemberData(null);
    const res = await api.getSearchMembers(value);
    setMembers(res);
    setIsLoadingMembers(false);
  };

  const handleCLickMember = async (member: Member) => {
    setIsLoadingDocuments(true);
    setValue(member.name);
    setPageDocuments(1);
    const res = await api.getSearchDocumentsByMember(member.id);
    setCommissionMemberData(res);
    setIsLoadingDocuments(false);
  };

  const handleClickDocument = (item: Document) => {
    setIsOpenModal((prevValue) => !prevValue);
    setSelectDocument(item);
  };

  const ref = useRef(null);

  const handleClickToggleModal = () => {
    setIsOpenModal((prevValue) => !prevValue);
  };

  const disabled = value.length < 3;

  const handleChangeMembersPage = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPageMembers(value);
  };

  const handleChangeDocumentsPage = (_event: React.ChangeEvent<unknown>, value: number) => {
    setPageDocuments(value);
  };

  const itemsPerPage = 10;

  const totalMembersPages = Math.ceil(members?.length / itemsPerPage);

  const totalDocumentsPages = Math.ceil(commissionMemberData?.news?.length / itemsPerPage);

  const paginatedMembersData = useMemo(() => {
    const startIndex = (pageMembers - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return members?.slice(startIndex, endIndex);
  }, [members, pageMembers, itemsPerPage]);

  const paginatedDocumentsData = useMemo(() => {
    const startIndex = (pageDocuments - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return commissionMemberData?.news?.slice(startIndex, endIndex);
  }, [commissionMemberData, pageDocuments, itemsPerPage]);

  return (
    <>
      <div className="input-container">
        <input
          className="input-user"
          placeholder="Введите фамилию кандидата"
          value={value}
          onChange={onChange}
        />
        <button disabled={disabled} onClick={handleCLickSearch} className={`input-search-btn ${disabled ? "disabled-input-search-btn" : ""}`}>
          <SVGIcon name="search" height="24px" width="24px" />
        </button>
      </div>
      {isLoadingMembers ? (
        <div className="loader-container">
          <Loader ContainerRef={ref} />
        </div>
      ) : (
        <>
          <p className="result-text">Участников комиссии найдено: {members?.length || 0}</p>
          <ul className="list-document">
            {paginatedMembersData &&
              paginatedMembersData?.map((item) => (
                <li key={item.id} className="item-document">
                  <button className="document-btn" onClick={() => handleCLickMember(item)}>{item.name}</button>
                </li>
            ))}
          </ul>
        </>
      )}
      {paginatedMembersData && members.length > 9 && (
        <Stack spacing={2}>
          <Pagination  count={totalMembersPages} page={pageMembers} onChange={handleChangeMembersPage} shape="rounded" />
        </Stack>
      )}
      {isLoadingDocuments ? (
        <div className="loader-container">
          <Loader ContainerRef={ref} />
        </div>
      ) : (
        <>
          {commissionMemberData?.news?.length >= 0 && (
            <p className="result-text">Результатов показано: {commissionMemberData?.news?.length || 0}</p>
          )}
          <ul className="list-document">
            {paginatedDocumentsData &&
              paginatedDocumentsData?.map((item) => {
                return (
                  <li className="item-document" key={item.id}>
                    <button onClick={() => handleClickDocument(item)} className="document-btn">
                      {prepareText(item.title, value, 'document-title')}
                      {prepareText(item.news_text, value, 'document-text')}
                    </button>
                  </li>
                );
              })}
          </ul>
        </>
      )}
      {paginatedDocumentsData && commissionMemberData?.news?.length > 9 && (
        <Stack spacing={2}>
          <Pagination  count={totalDocumentsPages} page={pageDocuments} onChange={handleChangeDocumentsPage} shape="rounded" />
        </Stack>
      )}
      {/* <DocumentModal
        commissionMemberData={commissionMemberData}
        selectedDocument={selectDocument}
        handleClickToggleModal={handleClickToggleModal}
        isOpenModal={isOpenModal}
        searchValue={value}
        // 
        mapRef={ props.mapRef }
        regions_data={ props.regions_data }
        data={ props.data }
        level={ props.level }
        change_temperature={ props.change_temperature }
        change_temperature_param={ props.change_temperature_param }
        temp_mode={ false }
        temperature_param={ props.temperature_param }
        temp_open_menu={ props.temp_open_menu }
        setTempOpenMenu={ props.setTempOpenMenu }
        allElection={props.allElection}
        selectedElectionEvent={props.selectedElectionEvent}
        setSelectedElectionEvent={props.setSelectedElectionEvent}
        handleSelectElEvent={props.handleSelectElEvent}
        selectedElectionEventData={props.selectedElectionEventData}
        selectedElectionCandidate={props.selectedElectionCandidate}
        handleSelectElCandidate={props.handleSelectElCandidate}
        nameRegion={props.nameRegion}
        isShowElectionPoints={props.isShowElectionPoints}
        setIsShowElectionPoints={props.setIsShowElectionPoints}
        activeMapLayerId={props.activeMapLayerId}
        isLoadingElectionPoints={props.isLoadingElectionPoints}
        setIsLoadingElectionPoints={props.setIsLoadingElectionPoints}
        popup={props.popup}
      /> */}
    </>
  );
};

export default DocumentBankGrid;
